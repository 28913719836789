.theme-mode {
  position: fixed;
  z-index: 10;
  top: 40%;
  right: 2%;
  @include flex(row);
  text-align: center;
  transform: rotate(180deg);
  text-orientation: mixed;
  writing-mode: vertical-lr;
  cursor: pointer;
  .dark-mode-toggle {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    line-height: 25;
    background: none;
    border: none;
    outline: none;
    color: var(--lightColor);
    transition: $colorTransition;
    &:after {
      @include pseudo-el(transparent, 1);
      width: 12px;
      height: 12px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      box-shadow: 0 0 0 0 transparent;
      animation: pulse 2s infinite;
      z-index: -1;
    }
    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 var(--lightColor);
      }
      70% {
        box-shadow: 0 0 0 20px transparent;
      }
      100% {
        box-shadow: 0 0 0 0 transparent;
      }
    }
    svg {
      display: block;
      width: 15px;
      height: 15px;
      transform: translateZ(0);
    }
  }
  span {
    display: block;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-size: 11px;
    white-space: nowrap;
  }
}
