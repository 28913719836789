%section-title {
  position: relative;
  font-size: clamp(1.5rem, 7vw, 2.5rem);
  font-family: $boldFont;
  text-transform: uppercase;
  text-align: center;
  transform: translateZ(0);
  margin-bottom: 3rem;
  // FOR SUPPORTING IE
  font-kerning: none;
  text-rendering: optimizeSpeed;
}
section.panel {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 5;
  background-color: var(--bgDark);
  transition: $bgTransition;
  overflow: hidden;
  h2.head-title {
    position: relative;
    display: inline-block;
    font-family: $mainFont;
    font-size: clamp(1.5rem, 7vw, 2rem);
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
    text-align: center;
    padding: 0.3rem 0;
    overflow: hidden;
    span.txt {
      display: block;
      transform: translateY(75px);
      transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
    }
    span.after {
      position: absolute;
      background: var(--lightColor);
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
    }
    &.active span.after {
      transform: scaleX(1);
    }
    &.active span.txt {
      transform: translateY(0);
      transition-delay: 0.1s;
    }
  }
}
section.intro {
  .scroll-down-wrapper {
    position: absolute;
    z-index: 10;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0;
    text-align: center;
    pointer-events: none;
    overflow: hidden;
    @include media(767px) {
      bottom: 70px;
    }
    .scroll-down {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 40px;
      height: 76px;
      overflow: hidden;
      .dots {
        position: relative;
        display: inline-block;
        width: 1px;
        height: 54px;
        overflow: hidden;
        .dot {
          display: inline-block;
          width: 1px;
          height: 2px;
          margin-top: 6px;
          opacity: 0;
          background: var(--lightColor);
          transition: transform 0.3s, $bgTransition;
          &:nth-child(6) {
            transform: translateY(18px);
            transition: transform 0.4s ease-out 0.25s, $bgTransition;
          }
          &:nth-child(5) {
            transform: translateY(26px);
            transition: transform 0.5s ease-out 0.25s, $bgTransition;
          }
          &:nth-child(4) {
            transform: translateY(34px);
            transition: transform 0.6s ease-out 0.25s, $bgTransition;
          }
          &:nth-child(3) {
            transform: translateY(42px);
            transition: transform 0.7s ease-out 0.25s, $bgTransition;
          }
          &:nth-child(2) {
            transform: translateY(50px);
            transition: transform 0.8s ease-out 0.25s, $bgTransition;
          }
          &:nth-child(1) {
            transform: translateY(58px);
            transition: transform 0.9s ease-out 0.25s, $bgTransition;
          }
          &.active {
            opacity: 1;
            transform: translateY(0);
          }
        } /* ./ .dot */
      } /* ./ .dots */
      .circle {
        display: block;
        transform: scale(0);
        background: var(--lightColor);
        transition: $bgTransition;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    } /* ./ .scroll-down */
    span.text {
      display: block;
      margin-top: 0.5rem;
      white-space: nowrap;
      letter-spacing: 3px;
      font-size: 10px;
      text-transform: uppercase;
      overflow: hidden;

      // FOR SUPPORTING IE
      font-kerning: none;
      -webkit-text-rendering: optimizeSpeed;
      text-rendering: optimizeSpeed;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  } /* ./ .scroll-down-wrapper */

  .img {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    @include flex(row);
    .globe {
      position: relative;
      width: 350px;
      height: 350px;
      margin-left: auto;
      flex-shrink: 0;
      perspective: 25em;
      transform-style: preserve-3d;
    }
    #tsparticles {
      width: 100%;
      height: 100%;
      transform: scale(0);
      &:hover {
        cursor: auto;
      }
      canvas {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .main-title {
    position: relative;
    width: 100%;
    height: 100vh;
    @include flex(row);
    justify-content: flex-start;
    h1.headline {
      @extend %section-title;
      font-size: clamp(2rem, 7vw, 3.5rem);
      text-align: left;
      margin-bottom: 0;
      padding: 1rem;
      span {
        display: block;
        position: relative;
        color: $mainColor;
        white-space: nowrap;
        overflow: hidden;
        & > div {
          clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
        }
      }
      .before-title {
        position: absolute;
        top: 50%;
        left: -370px;
        transform: translateY(-50%) scaleX(0);
        transform-origin: left;
        width: 350px;
        overflow: hidden;
        @include flex(row);
        justify-content: flex-start;
        @include media(575px) {
          display: none !important;
        }
        .line {
          width: 100%;
          height: 1px;
          background: $mainColor;
          transition: $bgTransition;
        }
        .circle {
          flex-shrink: 0;
          width: 9px;
          height: 9px;
          background-color: $mainColor;
          border-radius: 50%;
          transition: $bgTransition;
        }
      }
    } /* ./ h1.headline */
  } /* ./ .main-title */
}
section.why-us {
  height: auto;
  padding: 7rem 0;
  .down-arrow {
    position: absolute;
    top: 6rem;
    right: 10%;
    z-index: 1;
    @include media(767px) {
      display: none;
    }
    .vert-line {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 2px;
      height: 40vh;
      background: var(--lightColor);
      transition: $bgTransition;
      transform: scaleY(0);
      &:after {
        @include pseudo-el(var(--lightColor), 1);
        transition: $bgTransition;
      }
      .arr-1 {
        position: absolute;
        right: -19px;
        width: 0;
        height: 0;
        bottom: 8px;
        border-top: 20px solid var(--lightColor);
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
        z-index: -1;
        opacity: 0;
        transform: translateY(-100px);
        transition: border-color 0.5s ease;
        .arr-2 {
          position: absolute;
          top: -18px;
          left: -15px;
          width: 0;
          height: 0;
          border-top: 16px solid var(--bgDark);
          border-right: 15px solid transparent;
          border-left: 15px solid transparent;
          transition: border-color 0.5s ease;
        }
      }
    }
  }
  .svg-drawing {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 6rem;
    svg#why-us-title {
      display: block;
      width: 60%;
      @include media(767px) {
        width: 95%;
      }
      > path {
        stroke: var(--lightColor);
        transition: stroke 0.5s ease;
      }
    }
  }
  .why-items {
    li {
      text-transform: capitalize;
      font-family: $lightFont;
      font-size: clamp(1.2rem, 4vw, 1.4rem);
      text-transform: uppercase;
      letter-spacing: 4px;
      overflow: hidden;
      span {
        display: block;
        opacity: 0;
        visibility: inherit;
        transform: translateY(100px);
      }
      @include media(575px) {
        font-size: calc(2.5vw + 0.5rem);
      }
    }
  }
  .animated-txt li {
    text-transform: capitalize;
    font-family: $boldFont;
    font-size: 2rem;
    line-height: 1;
    opacity: 0;
  }
}
section.promotion {
  @include flex(row);
  height: auto;
  min-height: 100vh;
  padding: 25rem 0 5rem;
  @include media(767px) {
    min-height: auto;
    padding: 2rem 0 5rem;
  }
  .lamp {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -25%);
    will-change: transform;
    opacity: 0.4;
    overflow: hidden;
    @include media(767px) {
      display: none;
    }
    &__img {
      img {
        display: block;
        max-width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
  svg#swiss-map {
    display: block;
    width: 50%;
    margin: auto;
    @include media(767px) {
      width: 95%;
    }
    path.map-path {
      fill: none;
      stroke: var(--lightColor);
      stroke-width: 1px;
      stroke-linecap: round;
      stroke-linejoin: round;
      transition: stroke 0.5s ease;
    }
  }
  ul.slide-text {
    position: relative;
    z-index: 5;
    margin-top: 2.5rem;
    li {
      font-family: $lightFont;
      font-size: clamp(1.5rem, 3vw, 1.5rem);
      line-height: 1.5;
      letter-spacing: 3px;
      text-align: center;
      overflow: hidden;
      span {
        display: block;
        transform: translateY(100px);
        opacity: 0;
        visibility: inherit;
      }
    }
  }
}
section.vision {
  overflow: hidden;
  .entrance-scene {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    svg {
      display: block;
      fill: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      transform-origin: 56.83% 50%;
      @include media(767px) {
        width: 85%;
        height: 85%;
        transform-origin: 66.83% 50%;
      }
      path {
        fill: var(--bgDark);
        transition: fill 0.5s ease;
      }
    }
  }
  .vision-video {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    @include flex(row);
    overflow: hidden;
    .video-container {
      position: relative;
      width: 90%;
      height: 90%;
      .overlay {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(20, 23, 30, 0.8);
        opacity: 0;
      }
      video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }
  .vision-content {
    @include flex(row);
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100vh;
    padding: 7rem 0;
    overflow: hidden;
    background: var(--bgDark);
    transition: $bgTransition;
    .vision-text {
      p {
        font-family: $lightFont;
        font-size: clamp(1.1rem, 3vw, 1.3rem);
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 2;
        text-align: justify;
        @include media(767px) {
          text-align: left;
        }
      }
    }
    .circle-container {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 330px;
      height: 330px;
      pointer-events: none;
      @include media(767px) {
        display: none;
      }
      .circle {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: scale(0.3);
        background-image: radial-gradient(circle, transparent 50%, rgba(255, 255, 255, 0.2));
        border: 1px solid rgba(0, 0, 0, 0.1);
        animation: circleWave 6s ease-out infinite both;
        transition: background-image 0.5s ease;
        .lightmode & {
          background-image: radial-gradient(circle, transparent 50%, rgba(0, 0, 0, 0.3));
        }
        &.circle-1 {
          animation-delay: 1.5s;
        }
        &.circle-2 {
          animation-delay: 3s;
        }
        &.circle-3 {
          animation-delay: 4.5s;
        }
      }
      @keyframes circleWave {
        0% {
          transform: scale(0.1);
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          transform: scale(1.15);
          opacity: 0;
        }
      }
    }
  }
}
section.our-technology {
  height: auto;
  padding: 10rem 0 5rem;
  background: var(--bgDark);
  transition: $bgTransition;
  section {
    @include flex(row);
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
  }
  .tech {
    padding: 5rem 0;
    &__title {
      position: relative;
      padding: 2rem 1rem;
      background: var(--lightColor);
      color: var(--bgDark);
      background-blend-mode: luminosity;
      transition: $bgTransition, $colorTransition;
      &:after {
        @include pseudo-el(transparent, 0.3);
        left: auto;
        right: 0;
        width: 130px;
        background: url("../img/pattern-bg.png") center/cover no-repeat;
        filter: grayscale(100%);
      }
      h3 {
        font-size: clamp(1.2rem, 3vw, 1.5rem);
        span {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          margin-top: -0.2rem;
          font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: lighter;
          font-size: 0.85rem;
          padding-right: 2rem;
          &:after {
            @include pseudo-el(var(--darkColor), 1);
            top: 48%;
            left: auto;
            right: 0;
            width: 1.5rem;
            height: 1px;
            transition: $bgTransition;
          }
        }
      }
    }
    .banner {
      width: 100%;
      @include flex(row);
      align-items: stretch;
      @include media(767px) {
        flex-direction: column;
      }
      &__inner {
        position: relative;
        z-index: 2;
        width: 50%;
        perspective: 700px;
        -webkit-transform-style: flat;
        transform-style: preserve-3d;
        @include media(767px) {
          width: 100%;
        }
        &--front {
          @include flex(column);
          position: relative;
          padding: 1.5rem;
          height: 100%;
          background: var(--bgLight);
          color: var(--bgDark);
          transition: $bgTransition, $colorTransition;
          transform: translatez(50px);
          transform-style: preserve-3d;
          .pattern-top,
          .pattern-bottom {
            position: absolute;
            top: 0;
            left: 0;
            width: 120px;
            height: 120px;
            background: url("../img/dots-pattern.png") center/cover no-repeat;
            z-index: 5;
            list-style: none;
            filter: grayscale(100%);
            transition: opacity 0.5s ease;
            .lightmode & {
              opacity: 0.3;
            }
          }
          .pattern-bottom {
            top: auto;
            left: auto;
            bottom: 0;
            right: 0;
            background: url("../img/dots-pattern-bottom.png") center/cover no-repeat;
          }
          &:before,
          &:after {
            @include pseudo-el(#404040, 1);
            height: 50px;
            transition: $bgTransition;
            .lightmode & {
              background: #9c9c9c;
            }
          }
          &:before {
            top: -50px;
            transform-origin: center bottom;
            transform: rotatex(90deg);
          }
          &:after {
            top: auto;
            bottom: -50px;
            transform-origin: center top;
            transform: rotatex(-90deg);
          }
          li {
            font-family: $lightFont;
            text-transform: uppercase;
            line-height: 2;
            letter-spacing: 3px;
          }
        }
      }
      &__img {
        position: relative;
        z-index: 1;
        width: 50%;
        height: 250px;
        box-shadow: inset 25px 0px 20px 10px rgba(0, 0, 0, 0.41);
        overflow: hidden;
        @include media(767px) {
          width: 100%;
        }
        img {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          animation: showMe 1.2s linear infinite;
          animation-play-state: paused;
          animation-delay: var(--delay);
        }
        @keyframes showMe {
          0% {
            visibility: visible;
            z-index: 100;
          }
          12.5% {
            visibility: visible;
            z-index: 100;
          }
          25% {
            visibility: hidden;
            z-index: 0;
          }
          100% {
            visibility: hidden;
            z-index: 0;
          }
        }
        &:hover img {
          animation-play-state: running;
        }
      }
      .banner__inner--b,
      .banner__inner--d {
        z-index: -1;
        .banner__inner--front {
          transform: translateZ(-50px);
          &:before {
            transform: rotatex(-90deg);
          }
          &:after {
            transform: rotatex(90deg);
          }
        }
      }
    }
  }
}
section.teams {
  padding-top: 5rem;
  height: auto;
  .team {
    margin-top: 5rem;
    &__img {
      position: relative;
      width: 80%;
      margin: auto;
      height: 350px;
      background: var(--lightColor);
      transition: $bgTransition;
      @include media(991px) {
        width: 75%;
      }
      &:before,
      &:after {
        @include pseudo-el(var(--lightColor), 1);
        transition: 0.5s all ease-out;
      }
      &:before {
        top: 4px;
        z-index: -10;
        opacity: 0.7;
        transform: rotate(4deg);
      }
      &:after {
        top: 8px;
        z-index: -20;
        opacity: 0.3;
        transform: rotate(-4deg);
      }
      &:hover:before,
      &:hover:after {
        transform: rotate(0deg);
      }
      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--lightColor);
        object-fit: cover;
        overflow: hidden;
        transition: all 0.5s ease;
        &:nth-child(1) {
          filter: contrast(150%) brightness(120%);
        }
        &:nth-child(2) {
          filter: grayscale(100%);
        }
      }
      &:hover img:nth-child(2) {
        opacity: 0;
        transform: skewX(-5deg);
      }
    }
    &__info {
      margin-top: 3rem;
      text-align: center;
      &--name {
        @extend %section-title;
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      &--title {
        letter-spacing: 4px;
        font-family: $lightFont;
        font-size: 16px;
      }
    }
  }
}
section.reservation {
  @include flex(row);
  padding: 0 1rem;
  border-bottom: 2px solid #101318;
  transition: border-color 0.5s ease, $bgTransition;
  .lightmode & {
    border-color: #dad9d9;
  }
  .cta {
    position: relative;
    display: inline-block;
    padding: 2rem;
    text-transform: uppercase;
    transform: translateX(-100%);
    border: 2px solid var(--lightColor);
    transition: border-color 0.5s ease;
    span {
      display: block;
      text-align: center;
    }
    &__text {
      @extend %section-title;
      font-family: $mainFont;
      font-size: clamp(1.5rem, 7vw, 2rem);
      letter-spacing: 4px;
      transform: none;
      margin-bottom: 0;
    }
  }
}
