#main-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 992;
  width: 14rem;
  transform: translate(-50%, -50%);
  perspective: 1000px;
  transform-style: preserve-3d;
  opacity: 0;
  visibility: inherit;
  @include media(575px) {
    width: 10rem;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transform: translateZ(400px);
    overflow: visible;
    .logo-chars {
      stroke: var(--darkColor);
      stroke-width: 2px;
      fill: none;
      &.circle {
        transform: translateY(-30px);
      }
    }
  }
}
