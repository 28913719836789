.go-to-up {
  position: fixed;
  bottom: 2rem;
  right: 2.5%;
  z-index: 10;
  @include flex(column);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s linear, visibility 0.2s linear;
  cursor: pointer;
  span:nth-of-type(1) {
    width: 1px;
    height: 30px;
    background: var(--lightColor);
    transform: scaleY(0);
    transform-origin: top;
    transition: height 0.4s cubic-bezier(0.42, 0, 0.54, 2.07) 0.4s,
      transform 0.4s cubic-bezier(0.42, 0, 0.54, 2.07) 0.4s, $bgTransition;
  }
  span:nth-of-type(2) {
    text-orientation: mixed;
    writing-mode: vertical-lr;
    white-space: nowrap;
    transform: translateY(30px) rotate(180deg);
    margin: 0.5rem 0;
    font-size: 0.65rem;
    letter-spacing: 3px;
    opacity: 0;
    visibility: hidden;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, $bgTransition;
  }
  span:nth-of-type(3) {
    width: 1px;
    height: 0;
    background: var(--lightColor);
    transition: height 0.4s ease-in-out;
  }
  &:hover span:nth-of-type(1) {
    height: 0;
  }
  &:hover span:nth-of-type(3) {
    height: 30px;
  }
  &.show {
    opacity: 1;
    visibility: visible;
    span:nth-of-type(1) {
      transform: scale(1);
    }
    span:nth-of-type(2) {
      opacity: 1;
      visibility: visible;
      transform: translateY(0) rotate(180deg);
    }
  }
}
