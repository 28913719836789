#splash-screen {
  position: relative;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--bgLight);
  pointer-events: none;
  z-index: 991;
}
