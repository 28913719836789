header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  #bar-1,
  #bar-2 {
    position: absolute;
    z-index: 1;
    display: block;
    background: #fafaf9;
    backface-visibility: hidden;
    box-shadow: 0 0 6px 2px transparent;
    transition: $bgTransition;
    .lightmode & {
      background: #2b2e34;
    }
  }
  #bar-1 {
    top: 0;
    right: 0;
    width: 150px;
    height: 15px;
    transform: scaleX(0);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
  }
  #bar-2 {
    top: -15px;
    right: 0;
    width: 15px;
    height: 150px;
    transform: scaleY(0);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
  }
  .hamburger-menu {
    position: absolute;
    top: 4rem;
    right: 5%;
    z-index: 10;
    .hm-mom {
      position: relative;
      @include flex(row);
      justify-content: flex-start;
      span.burger {
        position: relative;
        display: block;
        width: 3.5rem;
        height: 25px;
        cursor: pointer;
        &:after,
        &:before {
          @include pseudo-el(var(--lightColor), 1);
          top: 50%;
          bottom: 0;
          left: 13px;
          width: 30px;
          height: 1px;
          transform-origin: center left;
          transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), $bgTransition;
        }
        &:before {
          margin-top: -7px;
          transform: scaleX(0.75);
        }
        &:after {
          margin-top: 7px;
          transform: scaleX(0.5);
        }
        span.line {
          position: absolute;
          top: 50%;
          left: 13px;
          width: 30px;
          height: 1px;
          background-color: var(--lightColor);
          transition: transform 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), $bgTransition;
        }
        &:hover:before {
          animation: lineHoverTop 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &:hover:after {
          animation: lineHoverBottom 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        @keyframes lineHoverTop {
          0% {
            transform: scaleX(0.75);
          }
          32% {
            transform: scaleX(0);
            transform-origin: center left;
          }
          33% {
            transform: scaleX(0);
            transform-origin: center right;
          }
          65% {
            transform: scaleX(1);
            transform-origin: center right;
          }
          66% {
            transform: scaleX(1);
            transform-origin: center left;
          }
          to {
            transform: scaleX(0.75);
          }
        }
        @keyframes lineHoverBottom {
          0% {
            transform: scaleX(0.5);
          }
          32% {
            transform: scaleX(1);
            transform-origin: center left;
          }
          33% {
            transform: scaleX(1);
            transform-origin: center right;
          }
          65% {
            transform: scaleX(0);
            transform-origin: center right;
          }
          66% {
            transform: scaleX(0);
            transform-origin: center left;
          }
          to {
            transform: scaleX(0.5);
          }
        }
      }
    }
  } /* ./ .hamburger-menu */
  .mom-menu {
    position: absolute;
    z-index: 16;
    top: 0;
    right: 0;
    width: 65vw;
    height: 100vh;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    @include media (767px) {
      width: 100vw;
    }
    .menu {
      width: 100%;
      height: 100%;
      overflow: hidden;
      @include flex(row);
      justify-content: space-around;
      align-items: flex-start;
      background-color: var(--bgLight);
      background-image: url("../img/menu-bg.png");
      background-position: 40% 75%;
      background-size: 40%;
      background-repeat: no-repeat;
      color: var(--darkColor);
      transform: translateX(100%);
      transition: $bgTransition;
      &:after {
        @include pseudo-el(var(--bgLight), .9);
        z-index: -1;
        transition: $bgTransition;
        .lightmode & {
          opacity: 0.8;
        }
      }
      @include media(767px) {
        width: 100vw;
        padding: 1rem;
        background-size: 75%;
      }
      @include media(575px) {
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 2rem;
      }
      .exit-menu {
        position: absolute;
        z-index: 8;
        top: 3.7rem;
        right: 10%;
        width: 2rem;
        height: 2rem;
        will-change: transform;
        cursor: pointer;
        @include media(767px) {
          top: 3.2rem;
        }
        .exit-line {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 1px;
          transform-origin: center;
          will-change: transform;
          &.exit-line-1 {
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
          }
          &.exit-line-2 {
            transform: translateX(-50%) translateY(-50%) rotate(-45deg);
          }
          &:after,
          &:before {
            @include pseudo-el(var(--darkColor), 1);
            will-change: transform;
            transition: $bgTransition, transform 0.3s linear;
          }
          &:before {
            transform-origin: right center;
            transform: scaleX(1);
          }
          &:after {
            transform-origin: left center;
            transform: scaleX(0);
          }
          &.exit-line-1:after {
            transition: $bgTransition, transform 0.3s linear 0.15s;
          }
          &.exit-line-2:after {
            transition: $bgTransition, transform 0.3s linear 0.3s;
          }
          &.exit-line-2:before {
            transition: $bgTransition, transform 0.3s linear 0.15s;
          }
        }
        &:hover {
          transform: rotate(90deg);
          transition: transform 0.5s cubic-bezier(0.5, 0.1, 0, 0.99);
        }
        &:hover .exit-line:after {
          transform-origin: right center;
          transform: scaleX(1);
        }
        &:hover .exit-line:before {
          transform-origin: left center;
          transform: scaleX(0);
        }
      }
      .left {
        margin-top: 25%;
        @include media(575px) {
          @include flex(column);
          align-items: flex-start;
          justify-content: space-evenly;
          height: 100%;
          margin-top: 0;
        }
        .navbar-links {
          position: relative;
          .line {
            position: absolute;
            top: -2rem;
            left: 0;
            width: 40%;
            height: 1px;
            background: var(--darkColor);
            transform: scaleX(0);
            @include media(575px) {
              display: none;
            }
          }
          .navbar-link {
            overflow: hidden;
            display: block;
            span {
              display: inline-block;
              font-size: 2rem;
              text-transform: uppercase;
              pointer-events: none;
              opacity: 0;
              visibility: inherit;
              transition: transform 0.1s linear;
              @include media(767px) {
                font-size: 2rem;
              }
            }
          } /* ./ .navbar-link */
        } /* ./ .navbar-links */
        .contacts-mob {
          li {
            line-height: 1.2;
            font-size: 0.9rem;
          }
        }
        .social-links {
          margin-top: 5rem;
          @include flex(row);
          justify-content: flex-start;
          li {
            margin-right: 1rem;
            text-align: center;
            overflow: hidden;
            opacity: 0.8;
            font-size: 1.1rem;
          }
        } /* ./ .social-links */
      } /* ./ .left */
      .right {
        margin-top: 25%;
        .contacts {
          .contact-block {
            @include flex(row);
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 1rem;
            overflow: hidden;
            ion-icon {
              display: inline-block;
              margin-top: 0.2rem;
              margin-right: 0.8rem;
              font-size: 1.3rem;
              opacity: 0.8;
            }
            p {
              font-size: 1.2rem;
            }
            a {
              position: relative;
              font-size: 1.2rem;
              display: inline-block;
              @include media(767px) {
                font-size: 0.8rem;
              }
              &:before,
              &:after {
                @include pseudo-el(var(--darkColor), .8);
                top: auto;
                bottom: 0;
                height: 0.02rem;
                min-height: 1px;
                transform: scale(1);
                transform-origin: right;
                transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), $bgTransition;
              }
              &:before {
                background: var(--darkColor);
              }
              &:after {
                transform: scale(0);
                transform-origin: left;
              }
              &:hover:before {
                transform: scale(0);
                transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
              }
              &:hover:after {
                transform: scale(1);
                background: var(--lightColor);
                transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s, $bgTransition;
              }
            }
          } /* ./ .contact-block */
        } /* ./ .contacts */
        @include media(575px) {
          display: none !important;
        }
      } /* ./ .right */
    } /* ./ menu */
  }
} /* ./ header */
