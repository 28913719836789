.spinner {
  position: fixed;
  top: 3rem;
  left: 6rem;
  z-index: 10;
  @include media(767px) {
    left: 0rem;
  }
}
.rotatethis {
  animation: rotating 20s linear infinite;
  #rotated {
    font-family: $lightFont;
    font-size: 11px;
    letter-spacing: 3px;
    & > div span:first-child {
      margin-left: -5px;
    }
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
