
@mixin flex($direction) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}
@mixin pseudo-el($bg, $opacity) {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $bg;
  opacity: $opacity;
  z-index: 2;
}
@mixin media($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}