.cursor {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  mix-blend-mode: difference;
}
.cursor__follower {
  transition: transform 0.4s;
  width: 6px;
  height: 6px;
}
.hovered-cursor {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  mix-blend-mode: difference;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.2s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  &.hover {
    transform: scale(3);
    opacity: 1;
    visibility: visible;
  }
} /* ./ .hovered-cursor*/
@include media(767px) {
  .cursor,
  .cursor__follower,
  .hovered-cursor {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }
}
