.live-chat {
  position: fixed;
  bottom: 2.5rem;
  right: 5%;
  z-index: 10;
  opacity: 0;
  visibility: inherit;
  @include media(767px) {
    right: 8%;
  }
  &:after {
    @include pseudo-el(transparent, 1);
    width: 22px;
    height: 22px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0 0 0 0 transparent;
    animation: pulse 2s infinite;
    z-index: -1;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 var(--lightColor);
    }
    70% {
      box-shadow: 0 0 0 20px transparent;
    }
    100% {
      box-shadow: 0 0 0 0 transparent;
    }
  }
  a {
    @include flex(row);
    width: 40px;
    height: 40px;
    background: none;
    border: 1px solid var(--lightColor);
    transition: border-color 0.5s ease;
    border-radius: 50%;
    ion-icon {
      flex-shrink: 0;
      font-size: 1.2rem;
      color: var(--lightColor);
      transition: $colorTransition;
    }
  }
}
