html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; // It only works on Mac OS X/macOS.
  -moz-osx-font-smoothing: grayscale; // It only works on Mac OS X/macOS.
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scrollbar-width: none !important; // for firefox
  --darkColor: #14171e;
  --lightColor: #797979;
  --bgLight: #fff;
  --bgDark: #14171e;
}
@font-face {
  font-family: "Helvetica-light";
  src: url("../fonts/Helvetica/HelveticaNeueLt.ttf") format("truetype");
}
// GOTHAM FONT
@font-face {
  font-family: "Gotham-light";
  src: url("../fonts/Gotham/GothamLight.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham-book";
  src: url("../fonts/Gotham/GothamBook.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: url("../fonts/Gotham/GothamMedium.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham-bold";
  src: url("../fonts/Gotham/GothamBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham-black";
  src: url("../fonts/Gotham/Gotham-Black.otf") format("opentype");
}
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
  background-color: var(--bgDark);
  color: var(--lightColor);
  font-family: $mainFont;
  transition: $bgTransition, $colorTransition;
  cursor: none;
  @include media(767px) {
    cursor: auto !important;
  }
  &.lightmode {
    --darkColor: #797979;
    --lightColor: #14171e;
    --bgDark: #fff;
    --bgLight: #14171e;
  }
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar-track {
  background: transparent !important;
}
::-webkit-scrollbar-thumb {
  background: transparent !important;
}
a,
a:hover,
a:active,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
  user-select: none;
}
ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  user-select: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  user-select: none;
}
