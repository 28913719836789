footer.footer {
  position: relative;
  z-index: 5;
  width: 100%;
  background: var(--bgDark);
  transition: $bgTransition;
  .ultimate-footer {
    font-family: $lightFont;
    padding: 5rem 0 2rem;
    .footer-title {
      h2 {
        font-family: 'Gotham-bold';
        font-size: 22px;
        margin-bottom: 7rem;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 4px;
      }
      span {
        display: block;
        font-size: 17px;
        font-family: $lightFont;
        letter-spacing: 8px;
        margin-top: 2rem;
      }
    }
    .contact {
      position: relative;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        letter-spacing: 3px;
        margin-bottom: 1.5rem;
        a {
          span {
            display: block;
          }
        }
        ion-icon {
          flex-shrink: 0;
          font-size: 1.5rem;
          display: block;
          margin-bottom: 1rem;
        }
      }
    }
    .social {
      position: relative;
      li {
        position: relative;
        width: 37px;
        height: 37px;
        background: transparent;
        transition: background 0.2s linear;
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--lightColor);
          border-radius: 3px;
          z-index: 1;
          transform: scaleY(0);
          transform-origin: center bottom;
          transition: transform 0.2s ease-in-out;
        }
        &:hover:after {
          transform: scaleY(1);
        }
        a {
          position: relative;
          z-index: 2;
          display: block;
          width: 100%;
          height: 100%;
          text-align: center;
          svg {
            fill: var(--lightColor);
            display: inline-block;
            vertical-align: middle;
            width: 18px;
            margin-top: 0.6rem;
            object-fit: cover;
            transition: fill .5s ease;
          }
        }
        &:hover svg {
          fill: var(--bgDark);
        }
      }
    }
    .copyright {
      text-align: center;
      p {
        font-size: 0.8rem;
        letter-spacing: 3px;
        a {
          font-size: 1.05rem;
        }
      }
    }
  }
}
