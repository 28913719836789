
$mainColor: #ca2026;
$darkColor: #14171e;
$bgTransition : background-color 0.5s ease;
$colorTransition : color 0.5s ease;
$mainFont: "Gotham-medium";
$boldFont: "Gotham-black";
$lightFont: 'Helvetica-light';


