.cookie-banner {
  position: fixed;
  z-index: 99;
  bottom: -100%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  font-family: $lightFont;
  padding: 3rem 0;
  box-shadow: 0 -2px 25px rgba(0, 0, 0, 0.3);
  background: var(--lightColor);
  transition: $bgTransition, bottom 1s ease-in, visibility 1s, opacity 1s;
  &.active {
    bottom: 0;
    opacity: 1;
    visibility: visible;
    transition: $bgTransition, bottom 1s ease-out, visibility 1s, opacity 1s;
  }
  p {
    text-transform: capitalize;
    color: var(--darkColor);
    transition: $colorTransition;
    a {
      color: $mainColor;
      text-decoration: underline;
      font-size: 1rem;
      font-weight: 600;
      &:hover {
        text-decoration: none;
      }
    }
  }
  button.cookie-btn {
    text-transform: capitalize;
    background: $mainColor;
    color: #a0a0a0;
    transition: $bgTransition;
    padding: 8px 40px;
    border-radius: 20px;
    border: none;
    outline: none;
  }
}