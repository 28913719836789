.switch-site {
  position: fixed;
  z-index: 10;
  bottom: 2rem;
  left: 2%;
  opacity: 0;
  visibility: inherit;
  @include media(767px) {
    left: 8% !important;
  }
  .senergic-it {
    transform-origin: left;
    transform: rotate(-90deg);
    animation: flash 2s linear infinite;
    @keyframes flash {
      0% { opacity: 1; }
      50% { opacity: .1; }
      100% { opacity: 1; }
    }
    &:after {
      @include pseudo-el(var(--lightColor), 1);
      width: 30px;
      height: 1px;
      top: 0.9rem;
      left: auto;
      right: -38px;
      transition: $bgTransition;
    }
  }
}