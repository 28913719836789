// vendors files

@import "vendors/bootstrap";
@import "vendors/locomotive-scroll";

// rest files

@import "utilities/variables";
@import "utilities/mixins";
@import "base/global";
@import "layout/header";
@import "components/splash-screen";
@import "components/main-logo";
@import "components/cursor";
@import "components/spinner";
@import "components/cookies";
@import "components/theme";
@import "components/it";
@import "components/chat";
@import "components/go-to-up";
@import "layout/forms";
@import "pages/index";
@import "layout/footer";

.c-scrollbar {
  display: none !important;
}